import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
    SearchOutlined,
    PhoneOutlined,
    HistoryOutlined,
    HeartOutlined,
    ShoppingCartOutlined,
    UserOutlined
} from "@ant-design/icons";
import './header.css'
import { HiMenuAlt1 } from 'react-icons/hi'
import { Drawer, DrawerProps, Dropdown, MenuProps, Switch, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TypeStore, dispatchAuthenModal, dispatchSearch, dispatchTheme, dispatchUserLogin } from "store/reducers";
import { CiLogin } from 'react-icons/ci'
import { AuthModal } from "components/log";
import { LinkHeader } from "./component/link-header";
import { getAuth, signOut } from "firebase/auth";
import { app } from "firebase.config";
import { NotificationPlacement } from "antd/es/notification/interface";
import LoadingSpinner from "components/lds-spinner/lds-spinner";

export function Header() {

    const dispatch = useDispatch()

    const { cart, userLogin, themelayout, system } = useSelector((state: TypeStore) => state)
    const [api, contextHolder] = notification.useNotification();

    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<DrawerProps['placement']>('left');
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [convertTheme, setConvertTheme] = useState(true)

    const onClose = () => {
        setOpen(false);
    };

    const checkUser = async () => {
        try {
            setIsLoading(true)
            const user = sessionStorage.getItem('Auth Token')
            if (user) {
                const currentUser = JSON.parse(user)
                dispatch(dispatchUserLogin(currentUser))
            } else {
                dispatch(dispatchUserLogin(null))
            }
            setIsLoading(false)
        } catch (error) {
            message.error('Lấy thông tin đăng nhập lỗi')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        checkUser()
    }, [])

    const openNotification = (placement: NotificationPlacement, value: string) => {
        api.info({
            message: `Trạng thái đăng nhập`,
            description: <span>{value}</span>,
            placement,
        });
    };

    const logout = async () => {
        try {
            setIsLoading(true)
            const firebaseAuth = getAuth(app);
            await signOut(firebaseAuth);
            sessionStorage.removeItem('Auth Token');
            dispatch(dispatchUserLogin(null));
            openNotification('topLeft', 'Đăng xuất thành công');
            sessionStorage.removeItem('idMessage')
        } catch (error) {
            openNotification('topLeft', 'Đăng xuất lỗi');
        } finally {
            setIsLoading(false)
        }
    };

    const count = useMemo(() => {
        const num = cart.reduce((num: number, cur: any) => num += (cur.qty ?? 0), 0)
        return num
    }, [cart])

    const items: MenuProps['items'] = [

        {
            key: '1',
            label: (
                <Link to={'/lich-su-mua-hang'} >
                    <div
                        className="flex-row min-fit gap-12 flex-row-center flex-between color-w pdlr-6 pdtb-4 bdr-4 dropdown-user history-in-dropdown">
                        <span className="text-13 f-400 color-w min-fit text-hover">
                            Lịch sử mua hàng
                        </span>
                        <HistoryOutlined className="color-w" />
                    </div>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <div className="flex-row flex-between flex-row-center color-w pdlr-6 pdtb-4 bdr-4 dropdown-user">
                    <p className="text-13 f-400">{themelayout.charAt(0).toUpperCase() + themelayout.slice(1)}</p>
                    <Switch defaultChecked={convertTheme} onChange={(checked, event) => {
                        event.preventDefault()
                        event.stopPropagation();
                        setConvertTheme(checked)
                    }} />
                </div>
            ),
        },
        {
            key: '3',
            label: (
                userLogin ? <div
                    onClick={logout}
                    className="flex-row flex-row-center flex-between color-w pdlr-6 pdtb-4 bdr-4 dropdown-user">
                    <p className="text-13 f-400 ">Đăng xuất</p>
                    <CiLogin className="text-18 error-color" />
                </div> :
                    <div
                        onClick={() => dispatch(dispatchAuthenModal("signin"))}
                        className="flex-row flex-row-center flex-between color-w pdlr-6 pdtb-4 bdr-4 dropdown-user">
                        <p className="text-13 f-400 ">Đăng nhập</p>
                        <CiLogin className="text-18 success-color" />
                    </div>
            ),
        },
    ];

    const onSearch = async (value: string) => {
        dispatch(dispatchSearch(value))
    }
    
    useEffect(() => {
        const defaultTheme = sessionStorage.getItem('theme');
        if (defaultTheme == null) {
            sessionStorage.setItem('theme', 'light');
            dispatch(dispatchTheme('light'));
        } else {
            if (defaultTheme === 'light') {
                setConvertTheme(true)
            } else {
                setConvertTheme(false)
            }
            dispatch(dispatchTheme(defaultTheme));
        }
    }, [dispatch]);

    useEffect(() => {
        if (convertTheme) {
            sessionStorage.setItem('theme', 'light');
            dispatch(dispatchTheme('light'));
        } else {
            sessionStorage.setItem('theme', 'dark');
            dispatch(dispatchTheme('dark'));
        }
    }, [convertTheme, dispatch]);

    return (
        <header id="header" className="header-class">
            {contextHolder}
            {isLoading && <LoadingSpinner />}
            <Drawer
                className="drawer-container"
                title={
                    <div className="flex-row max-100 gap-12 custom-input flex-row-center pdt-4 padb-4 bdr-4">
                        <input placeholder="Tìm kiếm" className="input color-w" onChange={(event) => setSearch(event.target.value)} />
                        <SearchOutlined className="color-w search-icon" onClick={() => onSearch(search)} />
                    </div>
                }
                placement={placement}
                closable={false}
                onClose={onClose}
                open={open}
                key={placement}
            >
                <div className="flex-col gap-12 list-links responsive">
                    <LinkHeader />
                </div>
            </Drawer>
            <div className="component-container flex-row flex-between gap-24">
                <div className="flex-row flex-center">
                    <HiMenuAlt1 className="icon-menu" onClick={() => setOpen(prev => !prev)} />
                    <Link to={"/"}>
                        <img src="/media/logo/logo-no-bg.png" alt="Nhang" className="logo" />
                    </Link>
                </div>
                <div className="flex-col gap-12 list-header-action" style={{
                    width: '100%'
                }}>
                    <div className="flex-row gap-24 flex-row-center mgl-auto w-100 flex-end">
                        <div className="flex-row max-100 gap-12 custom-input flex-row-center pdt-4 padb-4 bdr-4">
                            <input placeholder="Tìm kiếm" className="input color-w" onChange={(event) => setSearch(event.target.value)} />
                            <SearchOutlined className="color-w search-icon" onClick={() => onSearch(search)} />
                        </div>
                        <div className="flex-row gap-12 flex-row-center phone">
                            <PhoneOutlined className="color-w" />
                            <span className="text-13 f-400 color-w min-fit">
                                {system.sdt}
                            </span>
                        </div>
                        <div className="flex-row gap-12 flex-row-center history">
                            <Link to={'/lich-su-mua-hang'} className="flex-row flex-center gap-12">
                                <HistoryOutlined className="color-w" />
                                <span className="text-13 f-400 color-w min-fit text-hover">
                                    Lịch sử mua hàng
                                </span>
                            </Link>
                        </div>
                        <div className="flex-row flex-center">
                            <HeartOutlined className="color-y text-hover" />
                        </div>
                        <div className="flex-row flex-center relative">
                            <Link to={'/gio-hang'} className="flex-col flex-center">
                                <ShoppingCartOutlined className="color-y text-hover" />
                            </Link>
                            <div className="count-cart">
                                {count}
                            </div>
                        </div>
                        <div className="flex-row flex-center">
                            <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
                                <UserOutlined className="color-y text-hover" />
                            </Dropdown>
                        </div>
                        <AuthModal />
                    </div>
                    <div className="flex-row gap-12 flex-row-center list-links">
                        <LinkHeader />
                    </div>
                </div>
            </div>
        </header>
    )
}