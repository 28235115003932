import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { FaFacebookSquare, FaGooglePlusSquare } from 'react-icons/fa'
import './index.css'
import { Button, Form, Input, notification } from "antd";
import { useDispatch } from "react-redux";
import { dispatchAuthenModal, dispatchUserLogin } from "store/reducers";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword
} from "firebase/auth";
import { app } from "firebase.config";
import { NotificationPlacement } from 'antd/es/notification/interface';
import LoadingSpinner from "components/lds-spinner/lds-spinner";
import { v4 as uuidv4 } from 'uuid';
import { getDocumentByKey } from "firebase.utils";

export function Signin() {

    const dispatch = useDispatch()
    const [api, contextHolder] = notification.useNotification();

    const [isLoading, setIsloading] = useState(false)

    const firebaseAuth = getAuth(app)

    const googleProvider = new GoogleAuthProvider()
    const facebookProvider = new FacebookAuthProvider()

    const openNotification = (placement: NotificationPlacement, value: string) => {
        api.info({
            message: `Trạng thái đăng nhập`,
            description: <span>{value}</span>,
            placement,
        });
    };

    const loginWithGoogle = async () => {
        try {
            setIsloading(true)
            const { user } = await signInWithPopup(firebaseAuth, googleProvider)
            sessionStorage.setItem('Auth Token', JSON.stringify(user))
            dispatch(dispatchUserLogin(user))
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập thành công')
            dispatch(dispatchAuthenModal(null))
            sessionStorage.setItem('idMessage', JSON.stringify(uuidv4()))
        } catch (error) {
            setIsloading(false)
            // console.log(error)
            openNotification('topLeft', 'Đăng nhập lỗi')
        } finally {
            setIsloading(false)
        }
    }

    const loginWithFacebook = async () => {
        try {
            setIsloading(true)
            const { user } = await signInWithPopup(firebaseAuth, facebookProvider)
            sessionStorage.setItem('Auth Token', JSON.stringify(user))
            dispatch(dispatchUserLogin(user))
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập thành công')
            dispatch(dispatchAuthenModal(null))
            sessionStorage.setItem('idMessage', JSON.stringify(uuidv4()))
        } catch (error) {
            // console.log(error)
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập lỗi')
        } finally {
            setIsloading(false)
        }
    }

    const handleLogin = async (values: any) => {
        const { phone } = values
        const email = `${phone}@gmail.com`
        const password = phone;
        
        await loginWithEmailAndPassword(email, password)        
}


    const signupWithEmailAndPassword = async (email: string, password: string) => {
        try {
            setIsloading(true)
            await createUserWithEmailAndPassword(firebaseAuth, email, password).then(
                (response) => {
                    const user = response.user
                    sessionStorage.setItem('Auth Token', JSON.stringify(user))
                    dispatch(dispatchUserLogin(user))
                }
            ).catch((error) => {
                console.log('error', error);
            })
            setIsloading(false)
            openNotification('topLeft', 'Đăng ký thành công')
            dispatch(dispatchAuthenModal(null))
        } catch (error: any) {
            setIsloading(false)
            console.log(error);
            
            if (error.code === 'auth/invalid-login-credentials') {
                openNotification('topLeft', 'Tài khoản đăng nhập không hợp lệ')
            } else if (error.code === 'auth/email-already-in-use') {
                openNotification('topLeft', 'Email đã tồn tại')
            } else {
                openNotification('topLeft', 'Đăng ký lỗi')
            }
        } finally {
            setIsloading(false)
        }
    }

    const loginWithEmailAndPassword = async (email: string, password: string) => {
        try {
            setIsloading(true)
            await signInWithEmailAndPassword(firebaseAuth, email, password).then(
                (response) => {
                    const user = response.user
                    sessionStorage.setItem('Auth Token', JSON.stringify(user))
                    dispatch(dispatchUserLogin(user))
                })
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập thành công')
            dispatch(dispatchAuthenModal(null))
            sessionStorage.setItem('idMessage', JSON.stringify(uuidv4()))
        } catch (error: any) {
            setIsloading(false)
            if (error.code === 'auth/invalid-login-credentials') {
              await  signupWithEmailAndPassword(email, password)    
            } else if (error.code === 'auth/wrong-password') {
                openNotification('topLeft', 'Sai mật khẩu')
            } else if (error.code === 'auth/user-not-found') {
                await signupWithEmailAndPassword(email, password)    

            } else {
                openNotification('topLeft', 'Đăng nhập lỗi')
            }
        } finally {
            setIsloading(false)
        }
    }

    return (
        <div className="flex-col gap-24">
            {contextHolder}
            {isLoading && <LoadingSpinner />}
            <h1 className="text-18 f-600">Đăng nhập</h1>
            <Form layout="vertical" className="form-login flex-col gap-12" onFinish={handleLogin}>
                <Form.Item
                name="phone"
                label="Số điện thoại"
                rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
                >
                    <Input className="bdr-4" placeholder="Số điện thoại" />
                </Form.Item>
                <Button className="login-action color-w" htmlType="submit">Đăng nhập</Button>

                {/* <div className="flex-row flex-center text-blur text-13">Hoặc</div>
                <div className="grid-col-2 gap-12">
                    <div onClick={loginWithGoogle} className="signin-gf-button bdr-4 google flex-row flex-between flex-row-center">
                        <span className="f-600 color-w text-13">
                            Đăng nhập với Google
                        </span>
                        <FaGooglePlusSquare className="color-w text-18" />
                    </div>
                    <div onClick={loginWithFacebook} className="signin-gf-button bdr-4 facebook flex-row flex-between flex-row-center">
                        <span className="f-600 color-w text-13">
                            Đăng nhập với Facebook
                        </span>
                        <FaFacebookSquare className="color-w text-18" />
                    </div>
                </div> */}
                {/* <div className="flex-row flex-center">
                    <span className="text-13 text-blur">
                        Bạn chưa có tài khoản? <span className="text-13 text-link text-hover" onClick={() => dispatch(dispatchAuthenModal("signup"))}>Đăng ký ngay</span>
                    </span>
                </div> */}
            </Form>
        </div>
    )
}