import axios from 'axios'
import dayjs from 'dayjs'
import { firestore } from 'firebase.config'
import { getAuth } from 'firebase/auth'
import { collection, getDocs, query } from 'firebase/firestore'
import { ItemType } from 'modal/index'
import { Dispatch } from 'redux'
import { dispatchSystem } from 'store/reducers'

export const fetchCart = () => {
    const cartInfor = sessionStorage.getItem('cart')
    if (cartInfor) {
        const cart = JSON.parse(cartInfor)
        return cart
    } else {
        sessionStorage.removeItem('cart')
        return []
    }
}

export const fetchAuth = () => {
    const auth = getAuth();
    if (auth) {
        return auth
    } else {
        return null
    }
}

export const fetchSystem = async (dispatch: Dispatch) => {
    try {
        await getDocs(query(collection(firestore, 'system')))
            .then((querySnapshot) => {
                const newItems: any[] = [];
                querySnapshot.forEach((doc) => {
                    newItems.push({ id: doc.id, ...doc.data() } as any);
                });

                if (newItems.length > 0) {
                    dispatch(dispatchSystem(newItems[0]))
                }
            })
            .catch((error) => {
                console.error('Error getting documents: ', error);
            });
    } catch (error) {

    } finally {
    }
}

export const addToCart = (item: ItemType, count: number) => {
    const existValue = sessionStorage.getItem('cart')
    if (existValue) {
        const arrayValue: any = JSON.parse(existValue)
        if (arrayValue && Array.isArray(arrayValue)) {
            const exist = arrayValue.find((x) => x.id === item.id)
            if (exist) {
                const newValue = arrayValue.map((x) => x.id === item.id ? { ...exist, qty: exist.qty + count } : x)
                sessionStorage.setItem('cart', JSON.stringify(newValue))
            } else {
                const newValue = [...arrayValue, { ...item, qty: count }]
                sessionStorage.setItem('cart', JSON.stringify(newValue))
            }
        }
    } else {
        const newValue = [{ ...item, qty: count }]
        sessionStorage.setItem('cart', JSON.stringify(newValue))
    }
};

export const listIconsStore = [
    {
        src: "/media/market/shopee.png",
        alt: 'shopee'
    },
    {
        src: "/media/market/tiki.png",
        alt: 'tiki'
    },
    {
        src: "/media/market/sendo.png",
        alt: 'sendo'
    },
    {
        src: "/media/market/lazada.png",
        alt: 'lazada'
    },
    {
        src: "/media/market/zalo-icon.png",
        alt: 'zalo'
    },
]

export const NextClick = (props: any) => {
    const { onClick } = props
    return <div
        className="slider-action section next minus"
        onClick={onClick}
    >
        {">"}
    </div>
}

export const PrevClick = (props: any) => {
    const { onClick } = props
    return <div
        className="slider-action section prev minus"
        onClick={onClick}
    >
        {"<"}
    </div>
}

export function chuanHoaChuoi(chuoi: string) {
    let chuoiKhongDau = chuoi.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let chuoiChuanHoa = chuoiKhongDau.replace(/\s+/g, "");

    return chuoiChuanHoa;
}

export function smoothScroll(target: any) {
    const targetElement = document.getElementById(target);

    if (targetElement) {
        const offset = 100;
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth',
        });
    }
}

async function postWithRetry(url: string, data: any, config: any, retries = 5) {
    axios.post(url, data, config)
      .then(response => {
        console.log('Success:', response.data);
        // You can perform additional actions here when the request is successful
      })
      .catch(error => {
        if (retries > 0) {
          console.warn(`Retrying... (${retries} attempts left)`);
          postWithRetry(url, data, config, retries - 1);  // Retry the request
        } else {
          console.error('Failed after retries:', error);
          // Handle the error or notify the user after all retries have failed
        }
      });
  }

  
export async function sendNewOrderToTelegram(order: any) {

  const botToken = '7180877794:AAHlNQNc5qtTBQ_ZdDT3Dp9balPPgaQ_5sA';
  const chatId = '1207281995';
  const message = `
  🎉 **Bạn có đơn hàng mới** 🎉
  Ngày đặt: *${dayjs(order.paymentDate).format('HH:mm:ss DD/MM/YYYY')}*

  👤 **Thông tin khách hàng**:
  - Tên: *${order.fullname}*
  - Số điện thoại: *${order.phone}*
  - Địa chỉ: *${order.address}*
  
  📦 **Sản phẩm đã đặt**:
  ${order.products.map((product: any) => `- ${product.name} x ${product.qty}`).join('\n ')}
  
  📌 **Ghi chú**: ${order.note ? order.note : 'Không có ghi chú nào.'}
  💳 **Phương thức thanh toán**: ${order.payment ? order.payment : 'Chưa xác định'}
  💵 **Tạm tính**: **${Number(order.tempTotalPrice).toLocaleString()} VNĐ**
  💸 **Giảm giá**: **${order.discount}**

  💰 **Tổng tiền**: *${Number(order.total).toLocaleString()} VNĐ*
  
  Kiểm tra đơn ngay! 🎉
  `;
//   🚚 **Phí ship**: ${order.ship ? `${order.ship.toLocaleString()} VNĐ` : 'Miễn phí'}

  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

  return postWithRetry(url, {
    chat_id: chatId,
    text: message,
    parse_mode: 'Markdown',
  }, {
    timeout: 60000,
    
    // add headers all 
    headers: {
      'Content-Type': 'application/json',
      'referer': 'https://api.telegram.org',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        
    },  
  });

//   return fetch(url, {
//     redirect: "follow",
//     method: "POST",
//     body: JSON.stringify({
//         chat_id: chatId,
//         message,
//         parse_mode: 'Markdown',
//       }),
//       time
//     headers: {
//       "Content-Type": "text/plain;charset=utf-8",
//     },
//   })
};

// Example usage when a new order is placed
// const orderDetails = `Order ID: 1234\nProduct: Widget\nQuantity: 3`;
// sendTelegramMessage(orderDetails);

// sendNewOrderToGoogleSheet
export async function sendNewOrderToGoogleSheet(order: any) {
    const orderData = [
        dayjs(order.paymentDate).format('HH:mm:ss DD/MM/YYYY'),
        order.fullname,
        "'"+ order.phone,
        order.address,
        order.note,
        order.products.map((product: any) => `${product.name} x ${product.qty}`).join('\n'),
        order.payment,
        order.tempTotalPrice,
        order.discount,
        order.total,
    ]
    const url = 'https://script.google.com/macros/s/AKfycbzewauxIDx1WnzuFfGe8Y8aJawXMBHF-Tu-czL2j69O1dIABk-zmFieuXOsYwLKf3_b/exec';
    
    return fetch(url, {
        redirect: "follow",
        method: "POST",
        body: JSON.stringify(orderData),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      })
}


// send message to telegram notification when user chat with admin
export async function sendChatToTelegram(text: string) {
    const botToken = '7180877794:AAHlNQNc5qtTBQ_ZdDT3Dp9balPPgaQ_5sA';
    const chatId = '1207281995';
    // const botToken = '7546404566:AAGFA5Rlxs_EJrx9iRs7stG0Z5cMOV7mosk';
    // const chatId = '5003404232';

    const message = `
🎉 **Bạn có tin nhắn mới từ khách hàng** 🎉
💬 **Nội dung**: ${text}
⏰ *${dayjs().format('HH:mm:ss DD/MM/YYYY')}*
   `;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    return postWithRetry(url, {
        chat_id: chatId,
        text: message,
        parse_mode: 'Markdown',
    }, {
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            'referer': 'https://api.telegram.org',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
    });
}
