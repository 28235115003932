import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import './index.css'
import { DanhMucSanPham } from './slider-danh-muc';
import { message } from 'antd';
import { DocumentData, QueryDocumentSnapshot, collection, getDocs, query, startAfter, where } from 'firebase/firestore';
import { SearchItem } from 'service/firebase';
import { firestore } from 'firebase.config';
import LoadingSpinner from 'components/lds-spinner/lds-spinner';
import { SanPhamNoiBat } from './san-pham-noi-bat';
import { ListDM } from './danh-sach-san-pham-theo-muc';
import { ItemType } from 'modal/index';
import { NextClick, PrevClick } from 'utils/index';
import { useSelector } from 'react-redux';
import { TypeStore } from 'store/reducers';
import { Bestseller } from './bestseller';

interface TypeProps {
    banner: string,
    key: string,
    data: ItemType[]
}

export function Home() {
    const [items, setItems] = useState<TypeProps[]>([]);
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData, DocumentData> | null>(null);
    const [isLoading, setIsLoading] = useState(false)
    const { system } = useSelector((state: TypeStore) => state)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        nextArrow: <NextClick />,
        prevArrow: <PrevClick />,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const loadMore = async (key: string, banner: string) => {
        try {
            setIsLoading(true)
            let q = query(
                query(collection(firestore, 'items'), where("type", "==", key))
            );

            if (lastDoc) {
                q = query(q, startAfter(lastDoc));
            }

            const querySnapshot = await getDocs(q);

            const results: SearchItem[] = [];
            querySnapshot.forEach((doc) => {
                results.push({ id: doc.id, ...doc.data() } as any);
            });

            if (querySnapshot.docs.length > 0) {
                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }

            return {
                key: key,
                banner: banner,
                data: [...items, ...results]
            }
        } catch (error) {
            message.error('Lấy danh sách sản phẩm lỗi');
        } finally {
            setIsLoading(false)
        }
    };

    const fetchDM = async () => {
        try {
            setIsLoading(true)
            let q = query(collection(firestore, 'categories'), where("show", "==", true));

            const querySnapshot = await getDocs(q);

            const results: any[] = [];
            querySnapshot.forEach((doc) => {
                results.push({ id: doc.id, ...doc.data() } as any);
            });
            const data:any = await Promise.all(results.map((element) => loadMore(element.categoriesName, element.banner)))
            setItems(data)
        } catch (error) {
            message.error('Lấy danh sách sản phẩm lỗi');
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchDM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='mg-header component-container flex-col gap-24 mgb-24'>
            {isLoading && <LoadingSpinner />}
            <div className='slider-home'>
                <Slider {...settings}>
                    {system.banner?.map((element) => (
                         <img src={element} alt='Ảnh' className='img-slider' />
                    ))}
                </Slider>
            </div>
            <div className="main">
                <Bestseller />
                <SanPhamNoiBat />
                <ListDM items={items} />
            </div>

        </div>
    )
}