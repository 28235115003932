import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "./firebase.config";

export async function createDocument(collectionName: string, data: any) {
  const docRef = await addDoc(collection(firestore, collectionName), data);
  return docRef.id;
}

// Read a document
export async function getDocument(collectionName: string, documentId: string) {
  const docRef = doc(firestore, collectionName, documentId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

// get document by key
export async function getDocumentByKey(
  collectionName: string,
  key: string,
  value: any,
) {
  const q = query(
    collection(firestore, collectionName),
    where(key, "==", value),
  );
  const querySnapshot = await getDocs(q);

  let document: any = null;
  querySnapshot.forEach((doc: any) => {
    document = { id: doc.id, ...doc.data() };
  });
  return document;
}

export async function getDocuments(collectionName: string, options = {}) {
  try {
    const { limit: limitPage = 10, search = [] }: any = options;
    const [key, condition, value]: any = search;

    let q: any = query(
      collection(firestore, collectionName),
      // where is option
      key ? where(key, condition, value) : where("created", ">", 0),
      orderBy("created", "desc"),
      limit(limitPage),
    );

    const querySnapshot = await getDocs(q);

    const documents: any = [];
    querySnapshot.forEach((doc: any) => {
      documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
  } catch (error) {
    console.log("====================================");
    console.log(error);
    console.log("====================================");
    return [];
  }
}

// Update a document
export async function updateDocument(
  collectionName: string,
  documentId: string,
  data: any,
) {
  const docRef = doc(firestore, collectionName, documentId);
  await updateDoc(docRef, data);
}

// Delete a document
export async function deleteDocument(
  collectionName: string,
  documentId: string,
) {
  const docRef = doc(firestore, collectionName, documentId);
  await deleteDoc(docRef);
}
