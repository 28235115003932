import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeStore, dispatchAuthenModal } from "store/reducers";
import './index.css'
import { Signin } from "./signin";
import { Signup } from "./signup";

export function AuthModal() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const { authenModal } = useSelector((state: TypeStore) => state)

    useEffect(() => {
        if (authenModal != null) {
            setIsModalOpen(true)
        }
        if (authenModal == null) {
            setIsModalOpen(false)
        }
    }, [authenModal])

    const handleCancel = () => {
        dispatch(dispatchAuthenModal(null));
    };

    return (
        <Modal
            width={'900px'}
            className="modal-login"
            open={isModalOpen}
            onCancel={handleCancel}
            // onOk={handleOk} 
            footer={null}
        >
            <div className="flex-row bdr-4 ">
                <div className="frame-img-login">
                    <img src="/media/undraw_enter_uhqk.svg" className="img-login" alt="login" />
                </div>
                {
                    authenModal === 'signin'
                    && <div className="frame-login-form flex-col gap-24 pdl-24">
                        <Signin />
                    </div>
                }
                 {
                    authenModal === 'signup'
                    && <div className="frame-login-form flex-col gap-24 pdl-24">
                        <Signup />
                    </div>
                }
            </div>
        </Modal>
    )
}