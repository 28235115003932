import React from 'react'

export default function TitleBox({children}: any) {
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #e8e8e8',
      padding: '12px 0',
      backgroundColor:'rgb(16 22 40)',
      color: 'white',
    }}>
      {children}
    </div>
  )
}
