import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TypeStore } from "store/reducers";

export function LinkHeader() {

    const {system} = useSelector((state: TypeStore) => state)

    const listLinks = system.menu.filter((item) => item.open).map((m) => {
        return {
            key: `/${m.path}`,
            title: m.title?.toLocaleUpperCase()
        }
    })

    return <>
    {listLinks.map((element) => (
        <Link className="color-w text-hover text-12" to={element.key} key={element.key}>
            {element.title}
        </Link>
    ))}
        {/* <span className="color-w text-hover text-12">GIỚI THIỆU</span>
        <span className="color-w text-hover text-12">VÒNG TAY TRẦM HƯƠNG</span>
        <span className="color-w text-hover text-12">NHANG TRẦM HƯƠNG</span>
        <span className="color-w text-hover text-12">TRẦM HƯƠNG ĐỐT</span>
        <span className="color-w text-hover text-12">MỸ NGHỆ TRẦM HƯƠNG</span>
        <span className="color-w text-hover text-12">BLOG</span>
        <span className="color-w text-hover text-12">LIÊN HỆ</span> */}
    </>
}