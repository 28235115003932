import { Button} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeStore, dispatchCart } from "store/reducers";
import { Link, useNavigate } from "react-router-dom";
import { IoReturnUpBack } from 'react-icons/io5'
import './style.css'
import { addToCart } from "utils/index";
import { ItemType } from "modal/index";

export function Cart() {

    const { cart } = useSelector((state: TypeStore) => state)
    const dispatch = useDispatch()
    const history = useNavigate()

    const [reloadCart, setReloadCart] = useState(0)

    const onRemoveItem = (value: ItemType) => {
        const existingValue = sessionStorage.getItem('cart')
        if (existingValue) {
            const arrayCart: any[] = JSON.parse(existingValue)
            const newData = arrayCart.filter((item) => item.id !== value.id)
            sessionStorage.setItem('cart', JSON.stringify(newData))
            dispatch(dispatchCart(newData))
        }
    }

    const tamTinhGia = useMemo(() => {
        const price = cart.reduce((num: number, cur: any) => num += (cur.priceSale * cur.qty), 0)
        return price
    }, [cart])

    const onAddQty = (item: ItemType) => {
        addToCart(item, 1)
        setReloadCart(prev => prev + 1)
    };

    const onRemoveQty = (item: ItemType) => {
        const existValue = sessionStorage.getItem('cart')
        if (existValue) {
            const arrayValue: any = JSON.parse(existValue)
            if (arrayValue && Array.isArray(arrayValue)) {
                const exist = arrayValue.find((x) => x.id === item.id)
                if (exist) {
                    if (exist.qty - 1 < 1) {
                        onRemoveItem(item)
                    } else {
                        const newValue = arrayValue.map((x) => x.id === item.id ? { ...exist, qty: exist.qty - 1 } : x)
                        sessionStorage.setItem('cart', JSON.stringify(newValue))
                    }
                }
            }
        }
        setReloadCart(prev => prev + 1)
    };


    useEffect(() => {
        const existValue = sessionStorage.getItem('cart')
        if (existValue) {
            const arrayValue: ItemType[] = JSON.parse(existValue)
            dispatch(dispatchCart(arrayValue))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadCart])

    useEffect(() => {
        const targetElement = document.getElementById('scroll-cart');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    return (
        <div className="main">
            <div id="scroll-cart">
                <div className="default-container flex-col gap-32">
                    <div className="flex-row flex-center">
                        <h1 className="text-18 f-600">Giỏ hàng</h1>
                    </div>
                    <Button type="dashed" className="mgl-auto flex-row gap-4 flex-center" onClick={() => history(-1)}>
                        <IoReturnUpBack className="text-15" /> <span>Quay lại</span>
                    </Button>
                    <div className="flex-row gap-12">
                        <div className="container-payment">
                            <div className="payment-left flex-col">
                                <div className="paymment-item-grid info-item bd-b">
                                    <div className="grid-item big-col">
                                        <h1 className="text-14 f-600">Sản phẩm</h1>
                                    </div>
                                    <div className="grid-item flex-row flex-center">
                                        <h1 className="text-14 f-600">Giá</h1>
                                    </div>
                                    <div className="grid-item flex-row flex-center">
                                        <h1 className="text-14 f-600">Số lượng</h1>
                                    </div>
                                </div>
                                {cart.map((element, index: number) => (
                                    <div className={`paymment-item-grid info-item bd-b`}>
                                        <div className="grid-item big-col">
                                            <div className="flex-row gap-12">
                                                <span className="text-15 pointer flex-row flex-center" onClick={() => onRemoveItem(element)}>X</span>
                                                <img src={element.listFiles[0]} alt="cart" className="avatar-img" />
                                                <p className="text-13 f-400">{element.name}</p>
                                            </div>
                                        </div>
                                        <div className="grid-item flex-row flex-center">
                                            <h1 className="text-13 f-400">{Number(element.priceSale).toLocaleString()} VNĐ</h1>
                                        </div>
                                        <div className="grid-item flex-row flex-center">
                                            <div className="flex-row">
                                                <div className="flex-row flex-center pd-12 pointer" onClick={() => onRemoveQty(element)}>
                                                    <h1 className="text-13 f-400">-</h1>
                                                </div>
                                                <div className="flex-row flex-center pd-12">
                                                    <h1 className="text-13 f-400">{element.qty}</h1>
                                                </div>
                                                <div className="flex-row flex-center pd-12 pointer" onClick={() => onAddQty(element)}>
                                                    <h1 className="text-13 f-400">+</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="payment-right">
                                <div className="flex-col gap-12">
                                    <div className="flex-row box-border-bottom">
                                        <h1 className="text-14 f-600">Giỏ hàng</h1>
                                    </div>
                                    <div className="flex-row box-border-bottom">
                                        <div className="flex-row flex-between flex-100">
                                            <h1 className="text-13 f-500">Tạm tính</h1>
                                            <h1 className="text-13 f-500">{Number(tamTinhGia).toLocaleString()} VNĐ</h1>
                                        </div>
                                    </div>
                                    <div className="flex-row box-border-bottom">
                                        <div className="flex-row flex-between flex-100">
                                            <h1 className="text-13 f-500">Giao hàng</h1>
                                            <div className="flex-col gap-12">
                                                <p className="text-13 text-blur flex-row flex-end">Giao hàng miễn phí</p>
                                                <p className="text-13 text-blur flex-row flex-end gap-8">Vận chuyển đến <span className="text-13 text-default f-500">Đà Nẵng</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-row box-border-bottom">
                                        <div className="flex-row flex-between flex-100">
                                            <h1 className="text-13 f-500">Tổng</h1>
                                            <h1 className="text-13 f-500">{Number(tamTinhGia).toLocaleString()} VNĐ</h1>
                                        </div>
                                    </div>
                                    {cart.length > 0
                                        ? <Link to={'/dat-hang'}>
                                            <div className="button-payment">
                                                <h1 className="text-15 f-600">Tiến hành thanh toán</h1>
                                            </div>
                                        </Link>
                                        : <div className="button-payment no-cart">
                                            <h1 className="text-15 f-600">Tiến hành thanh toán</h1>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}