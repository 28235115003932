import React from 'react';
import './footer.css'
import { listIconsStore } from 'utils/index';
import { useSelector } from 'react-redux';
import { TypeStore } from 'store/reducers';

export function Footer() {

    const { system } = useSelector((state: TypeStore) => state)

    const openFile = (url: string) => {
        window.open(url, '_blank')
    }

    return (
        <footer className='flex-row footer-bg'>
            <div className="footer component-container flex-col gap-24">
                <div className='flex-row flex-row-center flex-evenly gap-32 flex-wrap'>
                    <img src="/media/logo/logo-no-bg.png" alt="Nhang binh an" className='logo-footer' />
                    <div className='flex-row bdr-4 frame-form-footer'>
                        <input className='input-email text-12' placeholder='Vui lòng nhập email' />
                        <div className='action-footer pd-12 flex-row flex-center pointer'>
                            <h1 className='text-12 f-600 min-fit'>Đăng ký</h1>
                        </div>
                    </div>
                </div>
                <div className="flex-row gap-12 flex-center">
                    {/* <a href="#"><i className="fa fa-facebook"></i></a>
                    <a href="#"><i className="fa fa-instagram"></i></a>
                    <a href="#"><i className="fa fa-youtube"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a> */}
                    {listIconsStore.map((element) => {
                        const data: any = system
                        const url = data[element.alt] ?? ''
                        return (
                            <div onClick={() => openFile(url)} className="flex-row box-shadow bdr-4 flex-center pd-4 bd-w pointer hover-scale"
                                style={{
                                    width: '50px',
                                    height: '50px'
                                }}
                            >
                                <img src={element.src} alt={element.alt} style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }} />
                            </div>
                        )
                    })}
                </div>
                <div className="row">
                    Copyright © 2023 - All rights reserved || Designed By: An
                </div>
            </div>
        </footer>
    )
}