import React, { useEffect, useState } from "react";
import './history.css'
import { Empty, Pagination, Table, message, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { TypeStore, dispatchAuthenModal } from "store/reducers";
import { DocumentData, QueryDocumentSnapshot, collection, getCountFromServer, getDocs, limit, query, startAfter, where } from "firebase/firestore";
import { firestore } from "firebase.config";
import './history.css'
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/lds-spinner/lds-spinner";
import dayjs from "dayjs";

export function History() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const history = useNavigate()

    const dispatch = useDispatch()

    const { userLogin } = useSelector((state: TypeStore) => state)

    const [itemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState<any[]>([]);
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData, DocumentData> | null>(null);
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const loadMore = async () => {
        try {
            setIsLoading(true)
            const phone = userLogin?.email?.split('@')[0]
            let q = query(collection(firestore, 'order'), where("phone", "==", phone));
            await getDocs(q)
                .then((querySnapshot) => {
                    const newItems: any[] = [];
                    querySnapshot.forEach((doc) => {
                        newItems.push({ id: doc.id, ...doc.data() } as any);
                    });
                    setItems(newItems);
                })
                .catch((error) => {
                    console.error('Error getting documents: ', error);
                });
            setIsLoading(false)
        } catch (error) {
            message.error('Lấy thông tin người dùng thất bại')
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (userLogin != null) {
            loadMore();
            dispatch(dispatchAuthenModal(null))
        } else {
            setItems([])
            dispatch(dispatchAuthenModal('signin'))
        }
    }, [itemsPerPage, userLogin, currentPage]);

    useEffect(() => {
        const targetElement = document.getElementById('scroll-history');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    const columns = [
        {
            title: 'Sản phẩm',
            dataIndex: 'products',
            key: 'products',
            render: (text: string, record: any) => record.products.map((item: any) => item.name).join(', '),
                
        },
        {
            title: 'Ngày mua',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            render: (paymentDate: string) => (
                <div className="flex-row flex-center">{dayjs(paymentDate).format('HH:mm:ss DD/MM/YYYY')}</div>
            ),
        },
        {
            width: 150,
            title: 'Tạm tính',
            dataIndex: 'tempTotalPrice',
            key: 'tempTotalPrice',
            render: (text: string) => (
                <div className="flex-row flex-center">{text?.toLocaleString() ?? '0'} VNĐ</div>
            ),
        },
        {
            width: 150,
            title: 'Giảm giá',
            dataIndex: 'discount',
            key: 'discount',
            render: (text: string) => (
                <div className="flex-row flex-center">{text ?? '0'}</div>
            ),
        },
        {
            width: 150,
            title: 'Tổng tiền',
            dataIndex: 'total',
            key: 'total',
            render: (text: string) => (
                <div className="flex-row flex-center">{text?.toLocaleString() ?? 0} VNĐ</div>
            ),
        },

    ];
    

    const columnsExpand = [
        {
            title: 'Sản phẩm',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => (
                
                <div className="flex-row gap-12">
                    <img className="image-product" src={record?.listFiles?.[0]} alt="San pham" />
                    <p className="text-14 f-400">{text}</p>
                </div>
            ),
        },
        {
            title: 'Giá',
            dataIndex: 'priceSale',
            key: 'priceSale',
            render: (text: string) => (
                <div className="flex-row flex-center">{Number(text).toLocaleString()} VND</div>
            ),
        },
        {
            title: 'Số lượng',
            dataIndex: 'qty',
            key: 'qty',
            render: (text: string) => (
                <div className="flex-row flex-center">{text}</div>
            ),
        },
        {
            title: 'Thuộc loại',
            dataIndex: 'type',
            key: 'type',
            render: (text: string) => (
                <div className="flex-row flex-center">{text}</div>
            ),
        },

        {
            title: 'Thao tác',
            dataIndex: 'id',
            key: 'id',
            render: (text: string) => (
                <div className="flex-row flex-center pointer text-link" onClick={() => history(`/chi-tiet/${text}`)}>Xem lại</div>
            ),
        },
    ];

    return (
        <div className="main">
            <div id="scroll-history">
                {isLoading && <LoadingSpinner />}
                <div className="flex-col gap-32 default-container">
                    <h1 className="text-15 f-600 text-center">
                        LỊCH SỬ MUA HÀNG
                    </h1>
                    <Table 
                    rowKey={'id'}
                                    columns={columns} 
                                    dataSource={items} 
                                    className="table-history" 
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <Table 
                                                columns={columnsExpand} 
                                                dataSource={record.products} 
                                                pagination={false} 
                                                className="table-history" 
                                                style={{ backgroundColor: colorBgContainer }}
                                                size="small"
                                            />
                                        ),
                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                    }}
                                    style={{ backgroundColor: colorBgContainer }}
                                />

                      
                </div>
            </div>
        </div>
    )
}