import { Button, Col, Flex, Form, Input, Radio, RadioChangeEvent, Row, Tag, Typography, message, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IoReturnUpBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TypeStore, dispatchCart } from "store/reducers";
import TextArea from "antd/es/input/TextArea";
import { saveOrder, updateItem } from "service/firebase";
import LoadingSpinner from "components/lds-spinner/lds-spinner";
import './checkout.css'
import { SubmitCheckout } from "modal/index";
import { sendNewOrderToGoogleSheet, sendNewOrderToTelegram } from "utils/index";
import { getDocument, getDocumentByKey, updateDocument } from "firebase.utils";
import dayjs from "dayjs";

const { Search } = Input;

export function Checkout() {
    const { cart, userLogin, themelayout } = useSelector((state: TypeStore) => state)
    const history = useNavigate()
    const [valueRadio, setValueRadio] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const dispacth = useDispatch()
    const [form] = Form.useForm()

    const [loadingDiscount, setLoadingDiscount] = useState(false)
    const [retryTele, setRetryTele] = useState(false)
    const [counter, setCounter] = useState({
        tele: 0,
        sheet: 0
    })
    const [orderTemp, setOrderTemp] = useState<any>({})
    const [discount , setDiscount] = useState<any>({})


    const tamTinhGia = useMemo(() => {
        const price = cart.reduce((num: number, cur: any) => num += (cur.priceSale * cur.qty), 0)
        return price 
    }, [cart,])


    const totalPrice = useMemo(() => {
       return  tamTinhGia -(discount?.value || 0)
    }, [tamTinhGia, discount?.value])


    const onUpdateItem = async (id: string, count: number) => {
        try {
            await updateItem(id, count)
        } catch (error) {
            message.error('Đặt hàng thất bại');
        }
    }


    // useEffect(() => {
    //     if (retrySheet && counter.sheet < 3) {
    //         sendNewOrderToGoogleSheet(orderTemp).then((res) => {
    //             setRetrySheet(false)
    //             setCounter((prev) => ({...prev, sheet: 0}))
    //         }).catch((error) => {
    //             setCounter((prev) => ({...prev, sheet: prev.sheet + 1}))
    //             setRetrySheet(true)
    //         })
    //     }
    // } , [retrySheet])

    useEffect(() => {
        if (retryTele && counter.tele < 3) {
            sendNewOrderToTelegram(orderTemp).then((res) => {
                setRetryTele(false)
                setCounter((prev) => ({...prev, tele: 0}))
            }).catch((error) => {
                setRetryTele(true)
                setCounter((prev) => ({...prev, tele: prev.tele + 1}))
            })
        }
    } , [retryTele])


    const onFinish = async (values: SubmitCheckout) => {
        try {
            setIsLoading(true)
            const data = {
                ...values,email:'', products: cart, userId: userLogin ? userLogin.uid : '', paymentDate: Date.now(), paymentStatus: false
            }
                const order ={
                    ...data,
                    payment:  valueRadio === 1 ? 'Thanh toán khi giao hàng (COD)' : 'Chuyển khoản ngân hàng',
                    tempTotalPrice: tamTinhGia,
                    discount: `${discount?.id ? (discount?.code + " - " + discount?.value?.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })) : ""}`,
                    total: totalPrice
                }

                 setOrderTemp(order)
                 sendNewOrderToGoogleSheet(order).then((res) => {
                    // setRetrySheet(false)
                 }).catch((error) => {
                    // setCounter((prev) => ({...prev, sheet: prev.sheet + 1}))
                    // setRetrySheet(true)
                    console.log('error google sheet', error);
                })
                 sendNewOrderToTelegram(order).then((res) => {
                    setRetryTele(false)
                 }).catch((error) => {
                    setCounter((prev) => ({...prev, tele: prev.tele + 1}))
                    setRetryTele(true)
                })
          
                await saveOrder(order)
                if(discount?.id){
                    await updateDocument('discounts', discount.id, {
                        status: 'applied'
                    })
                    setDiscount({})
                }
                const listId = cart.map((element) => {
                    return {
                        id: element.id,
                        count: element.purchases + element.qty
                    }
                })
                await Promise.all(listId.map((element) => onUpdateItem(element.id, element.count)))
                
                message.success('Đặt hàng thành công');
                setIsLoading(false)
                sessionStorage.removeItem('cart')
                dispacth(dispatchCart([]))
                history('/thanh-cong')
           
        } catch (error) {
            message.error('Đặt hàng thất bại');
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const targetElement = document.getElementById('scroll-checkout');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    // apply discount and check expired date and status with firebase
    const handleApplyDiscount = async (value: string) => {
        try {
        setDiscount({})
        setLoadingDiscount(true)
        
         const discountDoc = await getDocumentByKey('discounts', 'code', value.trim())
            if (discountDoc) {
                const expiredDate = dayjs(discountDoc.expiredDate).valueOf()
                const currentDate = dayjs().valueOf()

                if (expiredDate < currentDate) {
                    notification.error({
                        message: 'Mã ưu đãi đã hết hạn',
                        description: 'Vui lòng sử dụng mã ưu đãi khác',
                    });

                    return
                }

                if (discountDoc.status !== 'active') {
                    notification.error({
                        message: 'Mã ưu đãi không hợp lệ',
                        description: 'Vui lòng sử dụng mã ưu đãi khác',
                    });
                    return
                }

                setDiscount(discountDoc)

                notification.success({
                    message: 'Áp dụng mã ưu đãi thành công',
                    description: `Mã ưu đãi ${discountDoc.code} - ${discountDoc.value.toLocaleString()} VNĐ`,
                });

            } else {
                notification.error({
                    message: 'Mã ưu đãi không tồn tại',
                    description: 'Vui lòng sử dụng mã ưu đãi khác',
                });
            }
        } catch (error) {
            
        } finally {
            setLoadingDiscount(false)
        }
    }

    useEffect(() => {
        if (userLogin) {
            handleFindByPhone(userLogin.email.split('@')[0])
        }
    } , [userLogin])

    const handleFindByPhone = async (value: string) => {
        try {
            const data = await getDocumentByKey('order', 'phone', value)
            if (data) {
                form.setFieldsValue({
                    fullname: data.fullname,
                    phone: data.phone,
                    address: data.address
                })

                message.success('Lấy thông tin khách hàng thành công');
                return;
            }
            message.error('Bạn chưa có đơn hàng nào');
        } catch (error) {
            message.error('Có lỗi xảy ra');
        }
    }

    return (
        <div className="main">
            <div id="scroll-checkout">
                {isLoading && <LoadingSpinner />}
                <div className="default-container flex-col gap-32">
                    <div className="flex-row flex-center">
                        <h1 className="text-18 f-600">Thanh toán</h1>
                    </div>
                    <Button type="dashed" className="mgl-auto flex-row gap-4 flex-center" onClick={() => history(-1)}>
                        <IoReturnUpBack className="text-15" /> <span>Quay lại</span>
                    </Button>
                    <Form form={form} layout="vertical" className="form-design" onFinish={onFinish} style={{
                        color: themelayout === 'dark' ? 'white' : 'bkack'
                    }}>
                        <div className="flex-row gap-12">
                            <div className="container-payment">
                                <div className="payment-left flex-col gap-12">
                                    <Flex justify="space-between">
                                    <h1 className="text-15 f-600">THÔNG TIN THANH TOÁN</h1>
                                    <Flex vertical>
                                    {
                                        !userLogin && <>
                                        <Input.Search 
                                        placeholder="Số điện thoại" 
                                        enterButton="Nhập" 
                                        onSearch={handleFindByPhone}
                                        className={`min-h-input input-${themelayout}`}
                                        style={{
                                            width: 250
                                        }}
                                    />
                                     <Typography.Text style={{margin: 0, fontSize: 10, color: 'gray'}}>
                                        (Nhập số điện thoại để lấy thông tin khách hàng)
                                        </Typography.Text>
                                    </>
                                    }
                                    </Flex>
                                    </Flex>
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <Form.Item name={'fullname'} label="Họ và tên" className={`label-${themelayout}`} rules={[
                                                { required: true, message: 'Vui lòng nhập họ và tên' }
                                            ]}>
                                                <Input placeholder="Họ và tên" className={`min-h-input input-${themelayout}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={24} sm={24} xs={24}>
                                            <Form.Item name={'phone'} label="Số điện thoại" className={`label-${themelayout}`} rules={[
                                                { required: true, message: 'Vui lòng số điện thoại' },
                                                { pattern: /^(\+84|0)\d{9,10}$/, message: 'Số điện thoại không hợp lệ' }
                                            ]}>
                                                <Input placeholder="Số điện thoại" className={`min-h-input input-${themelayout}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={'address'} label="Địa chỉ liên hệ" className={`label-${themelayout}`} rules={[
                                                { required: true, message: 'Vui lòng địa chỉ liên hệ' },
                                            ]}>
                                                <Input placeholder="Địa chỉ liên hệ" className={`min-h-input input-${themelayout}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item initialValue={''} className={`label-${themelayout}`} name={'note'} label="Ghi chú">
                                                <TextArea placeholder="Ghi chú" className={`min-h-input input-${themelayout}`} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="payment-right checkout">
                                    <div className="flex-col gap-12">
                                        <h1 className="text-14 f-600">NHẬP MÃ ƯU ĐÃI</h1>
                                        <Search
                                            placeholder="Mã ưu đãi"
                                            allowClear
                                            enterButton="Áp dụng"
                                            className={`min-h-input input-${themelayout}`}
                                            size="large"
                                            onSearch={handleApplyDiscount}
                                            loading={loadingDiscount}
                                        />
                                            {
                                                discount?.id && (
                                                    <Tag style={{width: '100%'}} color="success">Mã giảm giá được áp dụng: -{discount?.value?.toLocaleString("vi-VN", {
                                                        style: "currency",
                                                        currency: "VND",
                                                      })}</Tag>
                                            )}
                                        <div className="flex-row box-border-bottom">
                                            <h1 className="text-14 f-600">ĐƠN HÀNG CỦA BẠN</h1>
                                        </div>
                                        {cart.map((element) => (
                                            <div className="flex-row box-border-bottom">
                                                <div className="flex-row flex-between flex-100 gap-12">
                                                    <h1 className="text-13 f-600">{element.name}</h1>
                                                    <h1 className="text-13 f-500 min-fit">x {element.qty ?? 0}</h1>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="flex-row box-border-bottom">
                                            <div className="flex-row flex-between flex-100">
                                                <h1 className="text-13 f-600 min-fit">Tạm tính</h1>
                                                <h1 className="text-13 f-500">{Number(tamTinhGia).toLocaleString()} VNĐ</h1>
                                            </div>
                                        </div>
                                        <div className="flex-row box-border-bottom">
                                            <div className="flex-row flex-between flex-100">
                                                <h1 className="text-13 f-600 min-fit">Giảm giá</h1>
                                                <h1 className="text-13 f-500">{Number(discount?.value || 0).toLocaleString()} VNĐ</h1>
                                            </div>
                                        </div>
                                        <div className="flex-row box-border-bottom">
                                            <div className="flex-row flex-between flex-100">
                                                <h1 className="text-13 f-600 min-fit">Giao hàng</h1>
                                                <div className="flex-col gap-12">
                                                    <p className="text-13 text-blur flex-row flex-end">Giao hàng miễn phí</p>
                                                    <p className="text-13 text-blur flex-row flex-end gap-8">Vận chuyển đến <span className={`text-13 f-500`} style={{
                                                        color: themelayout === 'dark' ? 'white' : 'black'
                                                    }}>Đà Nẵng</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-row box-border-bottom">
                                            <div className="flex-row flex-between flex-100">
                                                <h1 className="text-13 f-600 min-fit">Tổng</h1>
                                                <h1 className="text-13 f-500">{Number(totalPrice).toLocaleString()} VNĐ</h1>
                                            </div>
                                        </div>
                                        <Radio.Group
                                            style={{
                                                color: themelayout === 'dark' ? 'white' : 'black'
                                            }}
                                            className="flex-col gap-8" onChange={(e: RadioChangeEvent) => setValueRadio(e.target.value)} value={valueRadio}>
                                            <div className="flex-col">
                                                <Radio value={1}><h1 className="text-13 f-600"
                                                    style={{
                                                        color: themelayout === 'dark' ? 'white' : 'black'
                                                    }}
                                                >1. Thanh toán khi giao hàng (COD)</h1></Radio>
                                                <p className="text-13">
                                                    . Nhận hàng từ 1-2 ngày (Nội thành), 2-4 ngày (Tỉnh)
                                                </p>
                                                <p className="text-13">
                                                    . Kiểm tra Trầm đúng chất lượng rồi mới nhận hàng & trả tiền mặt.
                                                </p>
                                                <p className="text-13">
                                                    . Trường hợp không ưng ý, Quý Khách hoàn toàn có thể không nhận hàng và không mất phí vận chuyển.
                                                </p>
                                            </div>
                                            <Radio disabled value={2}><h1 className="text-13 f-600"
                                                style={{
                                                    color: themelayout === 'dark' ? 'white' : 'black'
                                                }}
                                            > 2. Chuyển khoản ngân hàng</h1></Radio>
                                            <Radio disabled value={3}><h1 className="text-13 f-600"
                                                style={{
                                                    color: themelayout === 'dark' ? 'white' : 'black'
                                                }}
                                            > 3. Thanh toán qua VNPAY</h1></Radio>
                                        </Radio.Group>
                                        <Button htmlType="submit" type="primary">
                                            Đặt hàng
                                        </Button>
                                        <p className="text-13">
                                            Thông tin cá nhân của bạn sẽ được sử dụng để xử lý đơn hàng, hỗ trợ trải nghiệm trên website, và cho các mục đích được mô tả trong chính sách bảo mật của chúng tôi.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}