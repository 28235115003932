import { Rate } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { TypeStore } from "store/reducers";
import { NextClick, PrevClick } from "utils/index";
import '../home/index.css'

export function SanPhamDaXem() {

    const [items, setItems] = useState<any[]>([])
    const { themelayout } = useSelector((state: TypeStore) => state)

    useEffect(() => {
        const sessionValue = sessionStorage.getItem('watched')
        if (sessionValue) {
            const parseSession = JSON.parse(sessionValue)
            if (parseSession && Array.isArray(parseSession)) {
                setItems(parseSession)
            }
        }
    }, [])

    const responsiveSettings = [
        {
            breakpoint: 960,
            settings: {
                slidesToShow: Math.min(4, items.length),
                slidesToScroll: Math.min(4, items.length),
            },
        },
        {
            breakpoint: 695,
            settings: {
                slidesToShow: Math.min(3, items.length),
                slidesToScroll: Math.min(3, items.length),
            },
        },
        {
            breakpoint: 490,
            settings: {
                slidesToShow: Math.min(2, items.length),
                slidesToScroll: Math.min(2, items.length),
            },
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        // autoplay: true,
        nextArrow: <NextClick />,
        prevArrow: <PrevClick />,
        slidesToShow: Math.min(4, items.length),
        slidesToScroll: Math.min(4, items.length),
        responsive: responsiveSettings,
    };

    return (
        <>
            {items.length > 0
                && <div className='flex-col gap-12'>
                    <h1 className="text-15 f-600 text-center">SẢN PHẨM ĐÃ XEM</h1>
                    <Slider {...settings} className="slider-fix pdb-12 pdt-12">
                        {items.map((element: any) => (
                            <div>
                                <Link className={`item slider ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`} to={`/chi-tiet/${element.id}`} key={element.id}>
                                <div className={`w-100 h-100 pointer box-shadow relative ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`}>
                                    <div className='devvn_label_product'>
                                        <span className="new">Sản phẩm mới</span>
                                    </div>
                                    <div className="item-frame-image">
                                        <img className='img-item bdr-t-4' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[0]} alt="" />
                                        {element.listFiles.filter((img: string) => !img.includes('.mp4')).length > 1 && <img className='img-item bdr-t-4 overlay-image' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[1]} alt="" />}
                                    </div>

                                    <div className="inf-item flex-col gap-8">
                                        <p className="text-13 f-400 text-center">{element.name}</p>
                                        <div className="flex-row flex-center gap-8 flex-wrap">
                                            <span className="text-13 f-500">{Number(element.priceSale).toLocaleString()} VNĐ</span>
                                            {element.price
                                                && <div className="flex-row flex-center relative">
                                                    <span className="text-12 f-500 text-blur">{Number(element.price).toLocaleString()} VNĐ</span>
                                                    <div className="line-center"></div>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex-row flex-center gap-8 flex-wrap">
                                            <Rate disabled value={element.rate} className={`text-15 ${themelayout}`} />
                                            <span className="text-12 f-500 text-blur">{element.reviews} đánh giá</span>
                                        </div>
                                    </div>

                                </div>
                            </Link>
                            </div>
                        ))}
                    </Slider>
                </div>
            }
        </>
    )
}