import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchCart } from 'utils/index'

const cartInfor = fetchCart()

interface Menu {
    path: string,
    open: boolean,
    title?: string,
}
export interface SyStem {
    id?: string | null
    banner: string[] | null,
    sdt: string | null,
    email: string | null,
    shopee: string | null,
    zalo: string | null,
    tiki: string | null,
    sendo: string | null,
    lazada: string | null,
    menu: Menu[]
}

export interface TypeStore {
    authenModal: null | 'signin' | 'signup',
    userLogin: any,
    cart: any[],
    currentUser: any,
    searchValue: string,
    themelayout: 'dark' | 'light',
    listKeyCategory: string[],
    system: SyStem
} 

const initialState: TypeStore = {
    authenModal: null,
    userLogin: null,
    cart: cartInfor,
    currentUser: null,
    searchValue: '',
    themelayout: 'light',
    listKeyCategory: [],
    system: {
        id: '',
        banner: [],
        sdt: '',
        email: '',
        shopee: '',
        tiki: '',
        zalo: '',
        sendo: '',
        lazada: '',
        menu: []
    }
}

const reducerSlice = createSlice({
    name: 'nhang',
    initialState,
    reducers: {
        dispatchAuthenModal: (state, action: PayloadAction< null | 'signin' | 'signup' >) => {
            state.authenModal = action.payload
        },
        dispatchUserLogin: (state, action: PayloadAction< any >) => {
            state.userLogin = action.payload
        },
        dispatchCart: (state, action: PayloadAction< any[] >) => {
            state.cart = action.payload
        },
        dispatchAuth: (state, action: PayloadAction< any >) => {
            state.currentUser = action.payload
        },
        dispatchSearch: (state, action: PayloadAction< string >) => {
            state.searchValue = action.payload
        },
        dispatchTheme: (state, action: PayloadAction< any >) => {
            state.themelayout = action.payload
        },
        dispatchListKeyCategory: (state, action: PayloadAction< string[] >) => {
            state.listKeyCategory = action.payload
        },
        dispatchSystem: (state, action: PayloadAction< any >) => {
            state.system = action.payload
        },
    }
})

export const {
    dispatchAuthenModal,
    dispatchUserLogin,
    dispatchCart,
    dispatchAuth,
    dispatchSearch,
    dispatchTheme,
    dispatchListKeyCategory,
    dispatchSystem
} = reducerSlice.actions;

export default reducerSlice.reducer;
