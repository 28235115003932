import { Rate } from "antd";
import LoadingSpinner from "components/lds-spinner/lds-spinner";
import { firestore } from "firebase.config";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TypeStore } from "store/reducers";

interface ICategory {
    id: string,
    label: string,
    value: string
}

export function BaiVietGanDay() {

    const [items, setItems] = useState<any[]>([])
    const { themelayout } = useSelector((state: TypeStore) => state)
    const [category, setCategory] = useState<ICategory[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const loadMore = async () => {
        try {
            setIsLoading(true)
            let q = query(collection(firestore, 'blogs'));

            // q = query(q, limit(5));

            await getDocs(q)
                .then((querySnapshot) => {
                    const newItems: any[] = [];
                    querySnapshot.forEach((doc) => {
                        newItems.push({ id: doc.id, ...doc.data() } as any);
                    });

                    if (newItems.length > 0) {
                        setItems([...items, ...newItems]);
                    }
                })
                .catch((error) => {
                    console.error('Error getting documents: ', error);
                });
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    };

    const fetchDMBlog = async () => {
        try{
            await getDocs(query(collection(firestore, 'blog-category')))
            .then((querySnapshot) => {
                const newItems: any[] = [];
                querySnapshot.forEach((doc) => {
                    newItems.push({ id: doc.id, ...doc.data() } as any);
                });

                if (newItems.length > 0) {
                    setCategory([...items, ...newItems]);
                }
            })
            .catch((error) => {
                console.error('Error getting documents: ', error);
            });
        }catch(error){

        }
    } 

    useEffect(() => {
        loadMore();
        fetchDMBlog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='flex-col gap-24 mgb-24'>
            {isLoading && <LoadingSpinner/>}
            {category.length > 0 && category.map((element) => (
                <section className="max-w-desktop pd-12 flex-col gap-24">
                <h1 className="text-15 mgt-24 f-600 text-center">{element.label?.toLocaleUpperCase()}</h1>
                <div className="grid-auto-fill">
                    {items.map((element: any) => {
                        const cleanedString = element.content.replace(/\*\*|\$\{.*?\}/g, '');
                        return (
                            <div>
                                <Link className={`item slider ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`} to={`/blog/${element.id}`} key={element.id}>
                                <div className={`w-100 h-100 pointer box-shadow relative w-100 ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`}>
                                    <div className="item-frame-image">
                                        <img className='img-item bdr-t-4' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[0]} alt="" />
                                        {element.listFiles.filter((img: string) => !img.includes('.mp4')).length > 1 && <img className='img-item bdr-t-4 overlay-image' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[1]} alt="" />}
                                    </div>
                                    <div className="inf-item flex-col gap-8">
                                        <p className="text-13 f-400 text-center bold">{element.title}</p>
                                        <div className="flex-row flex-center gap-8 flex-wrap">
                                            <span className="text-13 f-500 multiline-ellipsis">{cleanedString}</span>
                                        </div>
                                        <div className="flex-row flex-center gap-8 flex-wrap">
                                            <Rate disabled value={element.rate} className={`text-15 ${themelayout}`} />
                                            <span className="text-12 f-500 text-blur">{element.reviews} đánh giá</span>
                                        </div>
                                    </div>

                                </div>
                            </Link>
                            </div>
                        )
                    })}
                </div>
            </section>
            ))}
        </div>
    )
}