import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Header } from 'components/header/header';
import { PageError404 } from 'lib/core/page/not-found';
import { Home } from 'page/home';
import { Detail } from 'page/detail/chi-tiet';
import { Cart } from 'page/cart/cart';
import { Checkout } from 'page/checkout/checkout';
import { fetchAuth, fetchSystem } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { TypeStore, dispatchAuth } from 'store/reducers';
import ChatApp from 'components/box-chat/chat';
import { Success } from 'page/success/success';
import { History } from 'page/history/history';
import { List } from 'page/products-by-category/list';
import { Footer } from 'components/footer/footer';
import { Content } from 'antd/es/layout/layout';
import { Blog } from 'page/blog';
import { ChiTiet } from 'page/blog/chi-tiet';

function App() {

  const dispatch = useDispatch()
  const { themelayout } = useSelector((state: TypeStore) => state)

  useEffect(() => {
    const auth = fetchAuth()
    dispatch(dispatchAuth(auth?.currentUser))
    fetchSystem(dispatch)
  }, [dispatch])

  return (
    <Content
    className='flex-col flex-between'
      style={{
        background: themelayout === 'dark' ? '#06080f' : 'white',
        height: '100vh',
      }}
    >
      <BrowserRouter>
        <div className={`container ${themelayout  }`} style={{
          color: themelayout === 'dark' ? 'white' : 'black'
        }}>
          <Header />
            <ChatApp />
            <Routes>
              <Route path='/' element={<Navigate to='/trang-chu' />} />
              <Route path='/trang-chu' element={<Home />} />
              <Route path='/gio-hang' element={<Cart />} />
              <Route path='/blogs' element={<Blog />} />
              <Route path='/dat-hang' element={<Checkout />} />
              <Route path='/thanh-cong' element={<Success />} />
              <Route path='/lich-su-mua-hang' element={<History />} />
              <Route path='/danh-muc/:categories' element={<List />} />
              <Route path='/chi-tiet/:_id' element={<Detail />} />
              <Route path='/blog/:_id' element={<ChiTiet />} />
              <Route path='*' element={PageError404()} />
            </Routes>
          </div>
          <Footer />
        {/* </div> */}
      </BrowserRouter>
    </Content>
  );
}

export default App;
