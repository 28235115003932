import {getApp, initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import { getDatabase } from 'firebase/database'; 

//Chỉ sử dụng trong quá trình phát triển
const firebaseConfig = {
  apiKey: "AIzaSyCKHFrW-wdVe7rv6R3KD5K-eytPyt_sN7k",
  authDomain: "hoangtram-f17af.firebaseapp.com",
  projectId: "hoangtram-f17af",
  storageBucket: "hoangtram-f17af.appspot.com",
  messagingSenderId: "197139730815",
  appId: "1:197139730815:web:ac058985d4b8157f2b8a4a",
  measurementId: "G-MN8C5HPZQW"
};

const app = getApp.length > 0 ? getApp() : initializeApp(firebaseConfig)
const firestore = getFirestore(app)
const storage = getStorage(app)
const database = getDatabase(app);

export {app, firestore, storage, database}