import React from 'react'
import Slider from "react-slick";
import '../index.css'
import { Empty, Rate } from 'antd';
import { MdOutlineNavigateNext } from 'react-icons/md'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TypeStore } from 'store/reducers';
import { ItemType } from 'modal/index';
import { NextClick, PrevClick } from 'utils/index';

interface TypeProps {
    items: {
        banner: string,
        key: string,
        data: ItemType[]
    }[]
}

export function ListDM({ items }: TypeProps) {

    const { themelayout } = useSelector((state: TypeStore) => state)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        // autoplay: true,
        nextArrow: <NextClick />,
        prevArrow: <PrevClick />,
    };

    return (
        <>
            {items.map((element) => (
                <div className={`grid-template-home gap-8 pd-12 bdr-t-4 box-shadow bd`}>
                    <Link to={`/danh-muc/${element.key}`}>
                        <div className='frame-banner'>
                            <img className='banner' alt={element.key} src={element.banner} />
                        </div>
                    </Link>
                    <div className={`flex-col gap-12 w-100 col-3`}>
                        <div className='flex-row flex-between flex-row-center'>
                            <h1 className='text-18 f-600 pd-12'>{element.key}</h1>
                            <Link to={`/danh-muc/${element.key}`}>
                                <div className='flex-row gap-4 flex-center pointer'>
                                    <MdOutlineNavigateNext />
                                    <h1 className='text-14 f-400 mgr-24 white-space'>Xem tất cả</h1>
                                </div>
                            </Link>
                        </div>
                        <Slider {...settings}
                            responsive={[
                                {
                                    breakpoint: 960,
                                    settings: {
                                        slidesToShow: Math.min(element.data.length, 3),
                                        slidesToScroll: Math.min(element.data.length, 3),
                                    },
                                },
                                {
                                    breakpoint: 695,
                                    settings: {
                                        slidesToShow: Math.min(element.data.length, 3),
                                        slidesToScroll: Math.min(element.data.length, 3),
                                    },
                                },
                                {
                                    breakpoint: 490,
                                    settings: {
                                        slidesToShow: Math.min(element.data.length, 2),
                                        slidesToScroll: Math.min(element.data.length, 2),
                                    },
                                },
                            ]}
                            slidesToScroll={Math.min(element.data.length, 3)}
                            slidesToShow={Math.min(element.data.length, 3)}
                            className={`slider-fix max-width relative ${element.data.length < 3 ? `min-slider col-${element.data.length} normal` : ''}`}
                        >
                            {element.data.map((element: any) => (
                                <div>
                                    <Link className={`item slider ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`} to={`/chi-tiet/${element.id}`} key={element.id}>
                                        <div className={`w-100 h-100 pointer relative mini box-shadow ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`}>
                                            {element.typeProduct ? element.typeProduct === 'Sản phẩm mới'
                                                ? <div className='devvn_label_product'>
                                                    <span className="new">Sản phẩm mới</span>
                                                </div>
                                                : <div className='devvn_label_product'>
                                                    <span className="sale">Bán chạy</span>
                                                </div>
                                                : null
                                            }

                                            <div className="item-frame-image">
                                                {element?.listFiles && <>
                                                    <img className="img-item bdr-t-4" src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[0]} alt="" />
                                                    {element.listFiles.filter((img: string) => !img.includes('.mp4')).length > 1 && <img className='img-item bdr-t-4 overlay-image' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[1]} alt="" />}
                                                </>}
                                            </div>

                                            <div className="inf-item flex-col gap-8">
                                                <p className="text-13 f-400 text-center">{element.name}</p>
                                                <div className="flex-row flex-center gap-8 flex-wrap">
                                                    <span className="text-13 f-500">{Number(element.priceSale).toLocaleString()} VNĐ</span>
                                                    {element.price && <div className="flex-row flex-center relative">
                                                        <span className="text-12 f-500 text-blur">{Number(element.price).toLocaleString()} VNĐ</span>
                                                        <div className="line-center"></div>
                                                    </div>}
                                                </div>
                                                <div className="flex-row flex-center gap-8 flex-wrap">
                                                    <Rate disabled value={element.rate} className={`text-15 ${themelayout}`} />
                                                    <span className="text-12 f-500 text-blur">{element.reviews} đánh giá</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            ))}
        </>
    )
}