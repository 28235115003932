import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { FaFacebookSquare, FaGooglePlusSquare } from 'react-icons/fa'
import './index.css'
import { Button, Form, Input, notification } from "antd";
import { useDispatch } from "react-redux";
import { dispatchAuthenModal } from "store/reducers";
import { FacebookAuthProvider, GoogleAuthProvider, getAuth, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { NotificationPlacement } from "antd/es/notification/interface";
import { app } from "firebase.config";
import LoadingSpinner from "components/lds-spinner/lds-spinner";

export function Signup() {

    const dispatch = useDispatch()
    const [isLoading, setIsloading] = useState(false)
    const [api, contextHolder] = notification.useNotification();
    const firebaseAuth = getAuth(app)

    const googleProvider = new GoogleAuthProvider()
    const facebookProvider = new FacebookAuthProvider()

    const openNotification = (placement: NotificationPlacement, value: string) => {
        api.info({
            message: `Trạng thái đăng nhập`,
            description: <span>{value}</span>,
            placement,
        });
    };

    const loginWithGoogle = async () => {
        try{
            setIsloading(true)
            const { user } = await signInWithPopup(firebaseAuth, googleProvider)
            sessionStorage.setItem('Auth Token', user.refreshToken)
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập thành công')
            dispatch(dispatchAuthenModal(null))
        }catch(error){
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập lỗi')
        }finally{
            setIsloading(false)
        }
    }

    const loginWithFacebook = async () => {
        try{
            setIsloading(true)
            const { user } = await signInWithPopup(firebaseAuth, facebookProvider)
            sessionStorage.setItem('Auth Token', user.refreshToken)
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập thành công')
            dispatch(dispatchAuthenModal(null))
        }catch(error){
            setIsloading(false)
            openNotification('topLeft', 'Đăng nhập lỗi')
        }finally{
            setIsloading(false)
        }
    }

    const signupWithEmailAndPassword = async (email: string, password: string) => {
        try {
            setIsloading(true)
            await createUserWithEmailAndPassword(firebaseAuth, email, password).then(
                (response) => sessionStorage.setItem('Auth Token', response.user.refreshToken)
            )
            setIsloading(false)
            openNotification('topLeft', 'Đăng ký thành công')
            dispatch(dispatchAuthenModal("signin"))
        } catch (error: any) {
            setIsloading(false)
            if (error.code === 'auth/invalid-login-credentials') {
                openNotification('topLeft', 'Tài khoản đăng nhập không hợp lệ')
            } else if (error.code === 'auth/email-already-in-use') {
                openNotification('topLeft', 'Email đã tồn tại')
            } else {
                openNotification('topLeft', 'Đăng ký lỗi')
            }
        } finally {
            setIsloading(false)
        }
    }

    return (
        <div className="flex-col gap-24">
            {contextHolder}
            {isLoading && <LoadingSpinner/>}
            <h1 className="text-18 f-600">Đăng ký</h1>
            <Form layout="vertical" className="form-login flex-col gap-12" onFinish={(values) => signupWithEmailAndPassword(values.email, values.password)}>
                <Form.Item className="mgb-0" label={"Địa chỉ email của bạn"} name={'email'} rules={[
                    { required: true, message: 'Vui lòng nhập email của bạn' },
                    { pattern: /^(([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9\\-]+\.)+[a-zA-Z]{2,6})$/, message: 'Email không hợp lệ' }
                ]}>
                    <Input className="custom-input-login" placeholder="Vui lòng nhập email của bạn" />
                </Form.Item>
                <Form.Item className="mgb-0" label={"Mật khẩu"} name={'password'} rules={[
                    { required: true, message: 'Vui lòng nhập mật khẩu của bạn' },
                ]}>
                    <Input.Password
                        type="password"
                        className="custom-input-login"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        placeholder="Vui lòng nhập mật khẩu của bạn" />
                </Form.Item>
                <Form.Item
                    className="mgb-0"
                    label={"Xác nhận mật khẩu"}
                    hasFeedback
                    name="confirm"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng xác nhận lại mật khẩu!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Giá trị không trùng khớp với mật khẩu của bạn!'));
                            },
                        }),
                    ]}>
                    <Input.Password
                        className="custom-input-login"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        placeholder="Vui lòng nhập mật khẩu của bạn" />
                </Form.Item>
                <div className="flex-row flex-center text-blur text-13">Hoặc</div>
                <div className="grid-col-2 gap-12">
                    <div onClick={loginWithGoogle} className="signin-gf-button bdr-4 google flex-row flex-between flex-row-center">
                        <span className="f-600 color-w text-13">
                            Đăng nhập với Google
                        </span>
                        <FaGooglePlusSquare className="color-w text-18" />
                    </div>
                    <div onClick={loginWithFacebook} className="signin-gf-button bdr-4 facebook flex-row flex-between flex-row-center">
                        <span className="f-600 color-w text-13">
                            Đăng nhập với Facebook
                        </span>
                        <FaFacebookSquare className="color-w text-18" />
                    </div>
                </div>
                <Button className="login-action color-w" htmlType="submit">Đăng ký</Button>
                <div className="flex-row flex-center">
                    <span className="text-13 text-blur">
                        Bạn đã có tài khoản? <span className="text-13 text-link text-hover" onClick={() => dispatch(dispatchAuthenModal("signin"))}>Đăng nhập ngay</span>
                    </span>
                </div>
            </Form>
        </div>
    )
}