import { firestore } from "firebase.config";
import { addDoc, arrayUnion, collection, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import './chat.css'
import { BsFillChatDotsFill } from "react-icons/bs";
import { motion } from "framer-motion";
import { Button, Empty, Popover } from "antd";
import { useSelector } from "react-redux";
import { TypeStore } from "store/reducers";
import { useLocation, useParams } from "react-router-dom";
import { sendChatToTelegram } from "utils/index";

const text = <span>Nhắn tin ngay</span>;

function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement> {
    const ref = useRef<HTMLDivElement>();
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [dep]);
    return ref as any;
}

function ChatApp() {
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState<any[]>([]);
    const location = useLocation()
    console.log(location)
    const [idMessage, setIdMessage] = useState<any>('')
    const ref = useChatScroll(chatMessages)
    const { userLogin } = useSelector((state: TypeStore) => state)
    const [chatMessageOpen, setChatMessageOpen] = useState(false)
    const [initedMessage, setInitedMessage] = useState(false)
    const [countMessage, setCountMessage] = useState(0)

    const getChatMessages = (id: string) => {
        const messagesRef = collection(firestore, 'chatMessages');
        const q = query(messagesRef, where("idMessage", "==", id));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let messages: any[] = [];
            querySnapshot.forEach((doc) => {
                messages = doc.data().data;
            });

            messages.push({
                idMessage: '1',
                 timestamp: 1,
                 author: 'Admin',
                 text: "Cảm ơn bạn đã liên hệ shop, nếu chưa nhận được phản hồi, xin liên hệ zalo 0905792892!",
             });


            const data = messages.sort((a, b) => a.timestamp - b.timestamp);
            const userMessage = data.filter((element) => element.author !== 'Admin' || element.isAdmin)
            if (!chatMessageOpen && chatMessages.length < userMessage.length) {
                setCountMessage(userMessage.length - chatMessages.length)
            }
       
            setChatMessages(data);
        });

        return unsubscribe;
    };


    useEffect(() => {
        const idMessage = sessionStorage.getItem('idMessage')
        if (idMessage) {
            const id = JSON.parse(idMessage)
            getChatMessages(id);
            setIdMessage(id)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLogin]);

    useEffect(() => {
        if(chatMessageOpen && initedMessage === false) {
            // once init
            const id = uuidv4();
            setIdMessage(id)
            sessionStorage.setItem('idMessage', JSON.stringify(id))
            getChatMessages(id);
            setInitedMessage(true)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatMessageOpen, userLogin])

    const sendMessage = async () => {
        if (message.trim() !== '') {
            await addMessageToFirebase(message);
            setMessage('');
        }
    };

    const addMessageToFirebase = async (text: string) => {
        try {
            const messagesRef = collection(firestore, 'chatMessages');
            const q = query(messagesRef, where("idMessage", "==", idMessage))
            const data = await getDocs(q)
            if (data.empty) {
                await sendChatToTelegram(text);
                await addDoc(messagesRef, {
                    idMessage: idMessage,
                    unread: true,
                    timestamp: Date.now(),
                    author: userLogin ? (userLogin?.displayName ?? 'Khách mới') : 'Khách mới',
                    data: [
                        {
                            author: userLogin ? (userLogin?.displayName ?? 'Khách mới') : 'Khách mới',
                            text,
                            timestamp: Date.now(),
                        }
                    ]
                });
            } else {
                const docId = data.docs[0].id;
                const docRef = doc(firestore, 'chatMessages', docId);
                const newMessage = {
                    author: userLogin?.displayName ?? 'Khách mới',
                    text: text,
                    timestamp: Date.now(),
                };
                await updateDoc(docRef, {
                    unread: true,
                    timestamp: Date.now(),
                    data: arrayUnion(newMessage),
                });

            }
            setCountMessage(0)
        } catch (error) {
            console.error('Lỗi khi gửi tin nhắn:', error);
            setCountMessage(0)
        }
    };

    useEffect(() => {
        if (chatMessageOpen) {
            setCountMessage(0)
        }
    }, [chatMessageOpen])

    const content = (
        <div className="box-chat flex-col">
            <div className="box-chat-content gap-12" ref={ref}>
                {chatMessages.length > 0 ? chatMessages.map((chatMessage) => (
                    <div className={`flex-col ${chatMessage.author === 'Admin' ? 'flex-start' : 'flex-end'}`} key={chatMessage.id}>
                        <p className="text-13 f-600 white-color">{chatMessage.author === 'Admin' ? chatMessage.author : 'Bạn'}</p>
                        <div className={chatMessage.author === 'Admin' ? 'box-message gray' : "box-message you"}>
                            <p className="text-13 f-400">{chatMessage.text}</p>
                        </div>
                    </div>
                )) : <div className="flex-row flex-center">
                    <Empty className="white-color" description="Chưa có tin nhắn nào" />
                </div>}
            </div>
            <div className="flex-row gap-12 pd-12">
                <input className="input-send-message" value={message} placeholder="Nhập tin nhắn..." onChange={(e) => setMessage(e.target.value)} />
                <Button type="primary" onClick={sendMessage}>
                    Gửi
                </Button>
            </div>
        </div>
    );

    return (
        <Popover onOpenChange={setChatMessageOpen} className="custom-popo relative" placement="topRight" title={text} content={content} trigger="click">
            <motion.div initial={{ opacity: 0, scale: 0.6 }} exit={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} 
            className={`chat-container ${location.pathname.includes('/chi-tiet/') ? 'by-detail' : ''}`}>
                <BsFillChatDotsFill className="text-18" />
                {(countMessage > 0) && <div className="dot-message">
                </div>}
            </motion.div>
        </Popover>
    );
}

export default ChatApp;
